import cardValidator from "card-validator";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";

export const required = (value) => (value ? undefined : "Required");
export const requiredFn =
  (errorStr = "Required ") =>
  (value) =>
    value ? undefined : errorStr;
export const validateEmail = (value) =>
  isEmail(value) ? undefined : "Invalid email";
export const validateMobilePhone = (value) =>
  isMobilePhone(value, "en-US") ? undefined : "Invalid phone";
export const validatePostalCode = (value) =>
  isPostalCode(value, "US") ? undefined : "Invalid zip code";

export const validateExpDate = (value) => {
  const {isValid} = cardValidator.expirationDate(value);

  if (!isValid) {
    return "Invalid expiration date";
  }

  if (value.length < 5) {
    // return 'Invalid expiration date format (mm/yy)';
    return "Invalid expiration date";
  }
};

export function validateCreditCardNumber(value) {
  const unformattedNumber = (value || "").replace(/\s+/g, ""); // remove empty spaces
  const {isValid} = cardValidator.number(unformattedNumber);

  if (!isValid) {
    return "Invalid credit card number";
  }
}

export function validateCsv(value, {cardNumber}) {
  const {card} = cardValidator.number(cardNumber);
  const {isValid} = cardValidator.cvv(value, card?.code?.size);

  if (!isValid) {
    return "Invalid CVC";
  }
}

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
