import {React, useContext, useState} from "react";
import {Modal, Row, Col, InputGroup, FormLabel} from "react-bootstrap";
import {sendDeepLink} from "../../../lib/reservation-service";
import {Form, Field} from "react-final-form";
import {IdentifyPhoneNumber} from "../../../lib/analytics";
import {formatPhoneNumber, parsePhoneNumber} from "../Form/formatters";
import {
  composeValidators,
  required,
  validateMobilePhone,
} from "../Form/validators";
import Image from "next/legacy/image";
import styles from "./ActivationFlowModal.module.scss";
import classNames from "classnames";
import TextField from "../Form/TextField";
// import iPhone from "../../../public/images/activationModal/iphone-modal.png";
import downloadAppBgImage from "./download_app_bg@2x.png";
import closeButton from "../../../public/images/activationModal/close-button.svg";
import appStore from "../../../public/images/activationModal/app-store.svg";
import playStore from "../../../public/images/activationModal/play-store.svg";
import useUTMData from "../../context/utmData";
import {ZendeskChatContext} from "../../Zendesk/Zendesk";
import AppQRCode from "../AppQRCode/AppQRCode";
import Link from "next/link";

export default function ActivationFlowModal(props) {
  const {onHide} = props;
  const {showChat} = useContext(ZendeskChatContext);
  const [hasShownChat, setHasShownChat] = useState(false);
  const [linkSending, setLinkSending] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const getUTMData = useUTMData();

  const onSubmit = (values) => {
    const {mobileNumber} = values;

    const utmData = getUTMData();
    const data = {...utmData, mobileNumber};

    setLinkSending(true);

    sendDeepLink(mobileNumber)
      .then(() => {
        IdentifyPhoneNumber(data);

        setLinkSending(false);
        setLinkSent(true);
      })
      .catch((e) => {
        setLinkSending(false);
        setLinkSent(false);
      });
  };

  function handleOnCloseModal() {
    if (!linkSent && !hasShownChat) {
      showChat(true);
      setHasShownChat(true);
    }

    onHide();
  }

  return (
    <Modal
      {...props}
      onHide={handleOnCloseModal}
      centered
      contentClassName={styles.modalContent}
      dialogClassName={classNames("modal-xl", styles.modalDialog)}
    >
      <Modal.Body>
        <Row>
          <Col xs={12} lg={6}>
            <div className={styles.activationContent}>
              <h1 className={styles.heading}>Get Autonomy</h1>
              <p className={styles.subscribe}>
                Everything you need to subscribe is on your smartphone. 100%
                digital, right at your fingertips.
              </p>
              {linkSent ? (
                <p className={classNames(styles.sendLink, styles.sent)}>
                  Download link sent via text!
                </p>
              ) : (
                <p className={styles.sendLink}>
                  Send the download link to your phone
                </p>
              )}
              <Form
                initialValues={{
                  mobileNumber: "",
                }}
                onSubmit={onSubmit}
                subscription={{}}
                render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit}>
                    <label className={styles.label}>Mobile Number</label>
                    <InputGroup className={styles.ig}>
                      <div className={styles.cCode}>+1</div>
                      <Field
                        name="mobileNumber"
                        format={formatPhoneNumber}
                        parse={parsePhoneNumber}
                        validate={composeValidators(
                          required,
                          validateMobilePhone
                        )}
                      >
                        {(props) => (
                          <TextField
                            {...props}
                            className={styles.igInput}
                            type="tel"
                            autoComplete="tel"
                          />
                        )}
                      </Field>
                      <div>
                        <button
                          type="submit"
                          className={classNames(
                            styles.igBtn,
                            linkSent ? styles.disabled : ""
                          )}
                        >
                          {linkSending
                            ? "Sending... "
                            : !linkSending && linkSent
                            ? "Link Sent"
                            : "Send Link"}
                        </button>
                      </div>
                    </InputGroup>
                  </form>
                )}
              ></Form>
              <div className={styles.legal}>
                {`By tapping "Send Link," you agree to Autonomy's `}
                <Link href="/tos" legacyBehavior={true}>
                  <a
                    className={styles.link}
                    rel="follow noopener"
                    target="_blank"
                  >
                    TOS
                  </a>
                </Link>
                {` and to be
                contacted about Autonomy's products. This is not a condition of
                purchase.`}
              </div>

              <div className={styles.logos}>
                <div className={styles.appStoreWrapper}>
                  <div className={styles.text}>Scan to get the app</div>
                  <div className={styles.appStore}>
                    <Image
                      src={appStore}
                      layout="fixed"
                      width={120}
                      height={40}
                      alt="app store"
                    />
                  </div>
                </div>
                <div className={styles.playStoreWrapper}>
                  <div className={styles.playStore}>
                    <Image
                      src={playStore}
                      layout="fixed"
                      width={138.49}
                      height={40}
                      alt="play store"
                    />
                  </div>
                </div>
                <div className={classNames(styles.qrBtn, "me-2")}>
                  {props.show && <AppQRCode id="activation-flow-qrcode" />}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className={styles.iPhone}>
              <button
                className={styles.closeButton}
                onClick={handleOnCloseModal}
              >
                <Image
                  src={closeButton}
                  layout="fixed"
                  width={25}
                  height={25}
                  alt="close button"
                />
              </button>
              <Image
                src={downloadAppBgImage}
                layout="responsive"
                alt="iPhone"
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
